import { ContextApi } from 'contexts/Localization/types'

import {
  DropdownMenuItemType,
  MenuItemsType,
} from '@pancakeswap/uikit'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  
  {
    label: t('Earn'),
    href: '/farms',
    icon: 'Earn',
    items: [
      {
        label: t('Lockup Staking'),
        href: '/staking',
      }
    ],
  },
  
  {
    label: t('More'),
    href: '#',
    icon: 'More',
    hideSubNav: false,
    items: [
      {
        label: t('Explorer'),
        href: 'https://alveyscan.com/',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Bridge'),
        href: 'https://alveybridge.com/',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Website'),
        href: 'https://alveychain.com/',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      }
    ],
  }
]

export default config
